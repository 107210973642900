<template>
  <!-- eslint-disable-next-line -->
   
  <div class="relative flex w-full rounded-2xl bg-white p-4">
    <div
      ref="tableWrapper"
      class="no-scrollbar relative w-full overflow-y-hidden overflow-x-scroll"
      @scroll="checkScroll"
    >
      <table
        :id="tableId"
        class="w-max min-w-full table-fixed border-collapse rounded-xl"
        :class="{ 'border border-solid border-black-10': withBorder, 'h-full': !items.length }"
      >
        <thead>
          <tr class="bg-white [&>th]:bg-white" :class="{ 'pointer-events-none opacity-50': editRow }">
            <th
              v-if="selectable"
              class="sticky left-0 top-0 z-10 w-[62px] border-b border-solid border-black-40 px-4 py-3 text-left"
            >
              <UiInputCheckbox
                v-model="allSelected"
                class="mx-auto text-black-80"
                name="all"
                :indeterminate="indeterminate"
              />
              <transition name="fade">
                <div
                  v-if="showLeftOverflowBorder && columns.every((c) => !c.sticky) && !loading"
                  class="absolute right-0 top-0 h-[105%] w-px bg-black-40"
                  style="box-shadow: 4px 0px 10px rgba(15, 21, 29, 0.12)"
                ></div>
              </transition>
            </th>
            <th
              v-for="column in columns"
              :key="column.value"
              class="whitespace-nowrap border-b border-solid border-black-40 px-4 py-3 text-left text-black-70"
              :style="{ width: column.width || 'auto' }"
              :class="{
                'cursor-pointer ': column.sortable,
                'sticky left-0 top-0 z-10 w-auto': column.sticky,
                'sticky top-0 z-10 w-auto': column.stickyRight && items.length,
                'right-[57px]': column.stickyRight && items.length && $slots.action,
                'right-0': column.stickyRight && items.length && !$slots.action,
                'left-[62px]': selectable && !column.stickyRight,
              }"
              @click="column.sortable ? sort(column.sortable) : null"
            >
              <div
                v-if="column.text"
                class="flex flex-row items-center gap-1"
                :class="column.centerText ? 'justify-center' : ''"
              >
                {{ column.text }}
                <UiIcon
                  v-if="column.sortable"
                  name="chevron-sort"
                  size="xxs"
                  :class="column.sortable === currentSort ? 'text-black-70' : 'text-black-40'"
                ></UiIcon>
              </div>
              <transition name="fade">
                <div
                  v-if="column.sticky && showLeftOverflowBorder && !loading"
                  class="absolute right-0 top-0 h-[105%] w-px bg-black-40"
                  style="box-shadow: 3px 0px 10px 1px rgba(15, 21, 29, 0.12)"
                ></div>
              </transition>
              <transition name="fade">
                <div
                  v-if="column.stickyRight && showRightOverflowBorder && !loading && items.length"
                  class="absolute right-[98%] top-0 h-[105%] w-px bg-black-40"
                  style="box-shadow: -3px 0px 10px 1px rgba(15, 21, 29, 0.12)"
                ></div>
              </transition>
            </th>
            <th
              v-if="$slots.action"
              class="sticky right-0 top-0 z-10 border-b border-solid border-black-40 text-left"
              :class="showRightOverflowBorder ? 'w-0' : 'w-12 px-4'"
            >
              <slot name="header-action"></slot>
              <transition name="fade">
                <div
                  v-if="showRightOverflowBorder && columns.every((c) => !c.stickyRight) && !loading"
                  class="absolute left-0 top-0 h-[105%] w-px bg-black-40"
                  style="box-shadow: -3px 0px 10px 1px rgba(15, 21, 29, 0.12)"
                ></div>
              </transition>
            </th>
          </tr>
        </thead>
        <transition name="fade" mode="out-in">
          <tr v-if="!items.length && !loading">
            <td :colspan="columns.length + Number(Boolean($slots.action))">
              <slot name="empty"></slot>
            </td>
          </tr>
          <tr v-else-if="loading">
            <th :colspan="columns.length + 1">
              <UiLoader />
            </th>
          </tr>
          <tbody v-else>
            <tr
              v-for="(item, index) in items"
              :key="item.id"
              class="cursor-pointer select-none rounded-2xl"
              :class="[
                selectedIndexes[index]
                  ? 'bg-primary-20 hover:bg-primary-20 [&>td]:bg-primary-20 [&>td]:hover:bg-primary-20'
                  : highlightRowsIds?.includes(item.id)
                  ? 'bg-secondary-10 hover:bg-secondary-20 [&>td]:bg-secondary-10 [&>td]:hover:bg-secondary-20'
                  : 'bg-white even:bg-black-03 hover:bg-primary-10 [&>td]:bg-white [&>td]:even:bg-black-03 [&>td]:hover:bg-primary-10',
                tallRows ? 'h-14' : 'h-12',
                { 'pointer-events-none opacity-50': editRow && !item.edit },
              ]"
              @dblclick="$emit('dblclicked', item)"
              @click="clickedItem(item)"
              @contextmenu="rightClickedItem(item, $event)"
            >
              <td
                v-if="selectable"
                :id="`select_${item.id}`"
                key="select"
                class="text-body-2 peer sticky left-0 z-10 rounded-l-lg px-4"
              >
                <UiInputCheckbox
                  v-model="selectedIndexes[index]"
                  :name="item.id.toString()"
                  class="mx-auto"
                  @click.stop="selectedItem(index)"
                />
              </td>
              <td
                v-for="column in columns"
                :id="`${column.value || column.slot}_${item.id}`"
                :key="column.value || column.slot"
                :data-testing-attribute="getTestingAttribute(item, column)"
                :style="{ maxWidth: column.width || 'auto' }"
                :class="[
                  {
                    'sticky !z-[11]': column.sticky || column.stickyRight,
                  },
                  column.stickyRight
                    ? $slots.action
                      ? 'right-[57px]'
                      : 'right-0'
                    : selectable
                    ? 'left-[62px]'
                    : 'left-0',
                ]"
                class="text-body-2 peer z-0 h-full w-auto truncate whitespace-nowrap px-4 first:rounded-l-lg last:rounded-r-lg"
              >
                <NuxtLink v-if="navigateToUrl" :to="`${navigateToUrl}${item.id}`" class="text-body-2 no-underline">
                  <slot v-if="column.slot" :name="column.slot" :item="item" />
                  <span v-else>
                    {{ get(item, column.value, '-') }}
                  </span>
                </NuxtLink>
                <template v-else>
                  <slot v-if="column.slot" :name="column.slot" :item="item" />
                  <span v-else>
                    {{ get(item, column.value, '-') }}
                  </span>
                </template>
                <transition name="fade">
                  <div
                    v-if="column.sticky && showLeftOverflowBorder"
                    class="absolute right-0 top-0 h-[105%] w-px bg-black-40"
                    style="box-shadow: 3px 0px 10px 1px rgba(15, 21, 29, 0.12)"
                  ></div>
                </transition>
                <transition name="fade">
                  <div
                    v-if="column.stickyRight && showRightOverflowBorder"
                    class="absolute left-0 top-0 !z-[11] h-[105%] w-px bg-black-40"
                    style="box-shadow: -3px 0px 10px 1px rgba(15, 21, 29, 0.12)"
                  ></div>
                </transition>
              </td>
              <td
                v-if="$slots.action"
                :id="`actions_${item.id}`"
                ref="actionElement"
                class="text-body-2 sticky right-0 text-black-70 first:rounded-l-lg last:rounded-r-lg"
                :class="{
                  'opacity-0 transition-opacity duration-200 hover:opacity-100 peer-hover:opacity-100':
                    !showRightOverflowBorder,
                  'z-[12] opacity-100 hover:z-[12] peer-hover:z-[12]': actionClickedIndex === index,
                  'opacity-100': showAction,
                }"
                @click.stop="actionClickedIndex = index"
                @dblclick.stop
              >
                <transition name="fade">
                  <div
                    v-if="showRightOverflowBorder && columns.every((c) => !c.stickyRight)"
                    class="absolute left-0 top-0 z-[11] h-[105%] w-px bg-black-40"
                    style="box-shadow: -3px 0px 10px 1px rgba(15, 21, 29, 0.12)"
                  ></div>
                </transition>
                <div class="px-4">
                  <slot name="action" :item="item" :icon="item.icon || defaultIcon" />
                </div>
              </td>
            </tr>
          </tbody>
        </transition>
      </table>
      <!-- Pagination -->
      <div v-if="pagination" class="sticky inset-x-0 bottom-0 top-full mt-2 h-14 w-full">
        <div class="text-subhead-4 flex size-full flex-row items-center justify-between p-2 text-black-60">
          <UiInputMenu
            :model-value="pagination?.per_page"
            :items="paginationItems"
            name="pagination"
            :sort="false"
            class="relative w-64"
            @update:model-value="updatePerPage"
          >
            <template #activator="{ onClick, isOpen }">
              <div class="flex cursor-pointer flex-row items-center justify-start" @click="onClick">
                <div>Rows per page: {{ pagination.per_page }}</div>
                <UiIcon
                  name="chevron-big-filled-down"
                  :class="[{ 'rotate-180': isOpen }, 'transition-all duration-200']"
                ></UiIcon>
              </div>
            </template>
          </UiInputMenu>
          <div class="flex flex-row items-center gap-4">
            <div v-if="pagination.current_page && pagination.per_page">
              {{ (pagination.current_page - 1) * pagination.per_page + 1 }} - {{ pageItemsCount }} of
              {{ pagination.total >= 0 ? pagination.total : 0 }} items
            </div>
            <UiIcon
              id="previous"
              name="chevron-big-left"
              :class="!props.loading && pagination.current_page > 1 ? 'cursor-pointer' : 'text-black-20'"
              @click="previous"
            ></UiIcon>
            <UiIcon
              id="next"
              name="chevron-big-right"
              :class="
                !props.loading && pagination.current_page < pagination.total_pages ? 'cursor-pointer' : 'text-black-20'
              "
              @click="next"
            ></UiIcon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import get from 'lodash/get'
import { onClickOutside } from '@vueuse/core'
import type { TableColumn, Pagination, InputItem } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits([
  'previous',
  'next',
  'sort',
  'dblclicked',
  'clicked',
  'icon-clicked',
  'selected',
  'per-page-updated',
  'right-clicked',
])

type Props = {
  items: any[]
  columns: TableColumn[]
  pagination?: Pagination
  loading?: boolean
  selectable?: boolean
  defaultIcon?: string
  tableId?: string
  tallRows?: boolean
  navigateToUrl?: string
  withBorder?: boolean
  highlightRowsIds?: number[]
  showAction?: boolean
  editMode?: boolean
  dataTestingAttribute?: string
}
const props = withDefaults(defineProps<Props>(), {
  defaultIcon: 'more-vertical',
  pagination: undefined,
  highlightRowsIds: undefined,
  navigateToUrl: '',
  tableId: undefined,
  dataTestingAttribute: undefined,
})

const paginationItems = [
  {
    value: 20,
    text: '20',
  },
  {
    value: 30,
    text: '30',
  },
  {
    value: 50,
    text: '50',
  },
  {
    value: 100,
    text: '100',
  },
  {
    value: 150,
    text: '150',
  },
  {
    value: 200,
    text: '200',
  },
  {
    value: 250,
    text: '250',
  },
] as InputItem[]

const actionElement = ref()
const currentSort = ref()
const actionClickedIndex = ref<number | null>(null)
const showRightOverflowBorder = ref(false)
const showLeftOverflowBorder = ref(false)
const contentOverflows = ref(false)
const tableWrapper = ref()
const selectedIndexes = ref<boolean[]>([])

const clickedItem = (item: Object) => {
  emits('clicked', item)
}

const rightClickedItem = (item: Object, event: MouseEvent) => {
  event.preventDefault()
  emits('right-clicked', { item, event })
}

const selectedItem = (index: number) => {
  selectedIndexes.value[index] = !selectedIndexes.value[index]
  emits('selected', selectedIndexes.value)
}

const getTestingAttribute = (item: any, column: TableColumn) => {
  return `${column.value || column.slot}-${
    props.dataTestingAttribute ? get(item, props.dataTestingAttribute) : item.name || get(item, column.value, '-')
  }`
}

onClickOutside(actionElement, () => {
  if (editRow.value) return

  actionClickedIndex.value = null
})

watch(
  () => props.items,
  (value) => {
    selectedIndexes.value = props.items.map(() => false)
    if (value.length) {
      nextTick(() => {
        setTimeout(() => {
          checkScroll()
        }, 200)
      })
    }
  },
  { immediate: true }
)

const allSelected = computed({
  get() {
    return selectedIndexes.value.length ? selectedIndexes.value.every((i) => i === true) : false
  },
  set(value) {
    if (value) {
      selectedIndexes.value = selectedIndexes.value.map(() => true)
    } else {
      selectedIndexes.value = selectedIndexes.value.map(() => false)
    }
    emits('selected', selectedIndexes.value)
  },
})
const indeterminate = computed(() => selectedIndexes.value.includes(true) && selectedIndexes.value.includes(false))

const pageItemsCount = computed(() => {
  if (!props.pagination) return
  if (props.pagination.current_page === props.pagination.total_pages) {
    const assumedCount =
      (props.pagination.current_page - 1) * props.pagination.per_page + 1 + (props.pagination.count - 1)
    return assumedCount > props.pagination.total ? props.pagination.total : assumedCount
  } else {
    return props.pagination.per_page * props.pagination.current_page
  }
})

const resetSelected = () => {
  if (!props.pagination) return
  allSelected.value = false
  emits('selected', selectedIndexes.value)
}

const previous = () => {
  if (!props.pagination) return
  if (!props.loading && props.pagination.current_page > 1) {
    emits('previous')
  }
}
const next = () => {
  if (!props.pagination) return
  if (!props.loading && props.pagination.current_page < props.pagination.total_pages) {
    emits('next')
  }
}

const checkScroll = () => {
  contentOverflows.value = Number(tableWrapper.value?.scrollWidth) > Number(tableWrapper.value?.offsetWidth)
  if (contentOverflows.value) showLeftOverflowBorder.value = Number(tableWrapper.value.scrollLeft) > 0

  showRightOverflowBorder.value =
    Number(tableWrapper.value?.clientWidth) + Number(tableWrapper.value?.scrollLeft) + 1 <
    Number(tableWrapper.value?.scrollWidth)
}

const sort = (sortBy: string) => {
  emits('sort', sortBy)
  currentSort.value = sortBy
}

const editRow = computed(() => {
  if (!props.editMode) return false
  return props.items.find((i) => i.edit)
})

const updatePerPage = (value: number) => {
  uiStore.setTableItemsPerPage(value)
  emits('per-page-updated', value)
}

defineExpose({
  resetSelected,
})
</script>

<style scoped></style>
